import React, { Component } from "react";
import SEO from "../components/seo";
import { Flex, Box } from "@rebass/grid";

import Title from "../components/page-title";
import Copy from "../components/page-copy";
import Row from "../components/page-content-row";
import Image from "../components/page-image-new";
//import PageOverview from '../components/page-overview';
import PageResourceSide from "../components/page-resources-side";
import Image1 from '../images/resources/happy-haus-wollumbin-9.jpg'
const ogImages = [`https://happyhaus.com.au${Image1}`]


const PostImage = "resources/happy-haus-wollumbin-9.jpg";

class Page extends Component {
  render() {
    return (
      <>
        <SEO title="Sustainabilty in building practices" description="Environmental sustainability is a core element of the Happy Haus business philosophy. Happy Haus is committed to the
                  ongoing research, development and construction of homes that are sensitive towards nature." images={ogImages} />

        <Row>
          <Flex>
            <Box width={8 / 12} px={2}>
              <Image src={PostImage} ratio={9 / 16} margin={true} />
              <Title align="left">Sustainabilty in building practices</Title>
              <Copy align="left">
                <p>
                  Environmental sustainability is a core element of the Happy Haus business philosophy. Happy Haus is committed to the
                  ongoing research, development and construction of homes that are sensitive towards nature.
                </p>
                <p>
                  We strive to minimise the impact on our environment taking an approach that sees environmental considerations as a key
                  business driver, spanning all areas of our business. This includes our thinking and actions initially from the design of
                  our homes through to their ongoing performance.
                </p>
                <p>Sustainable buildings have the ability to positively impact their owners by:</p>
                <p>
                <ul>
                    <li>Conserving natural resources</li><li>Reducing waste streams</li><li>Reducing operating costs</li><li>Optimizing life-cycle economic
                  performance</li><li>Enhancing occupant comfort and health</li><li>Heightening aesthetic qualities</li><li>Minimizing strain on local
                  infrastructure</li><li>Improving overall quality of life.</li></ul>
                </p>
                <p><strong>Design is the Starting Point</strong></p>
                <p>
                  Happy Haus is built on the basis of working with some of Australia’s best architects to design our homes. In doing this we
                  ensure that all of our designs employ well considered knowledge about environmentally responsive design, a principle that
                  is inherent within good architects and good architecture. We are able to achieve this by:
                </p>
                <p>
                <ul>
                  <li>Investing in the best people to work with at Happy Haus</li>
                  <li>Factoring in environmental considerations in the design of all
                  </li><li>
                  our homes</li><li>Working with the natural environment when siting our homes to ensure orientation for the best conditions</li><li>
                  Maximising house footprints to reduce ongoing operating costs ie passive cooling and heating</li><li>Maximising the use of
                  natural lighting reducing the artificial lighting requirement.</li></ul>
                </p>
                <p><strong>Materials that Make Up Your Home</strong></p>
                <p>
                  Choosing the “right” type of materials in the design stage of building a home will impact on the long-term sustainability
                  of any building project. Happy Haus provides clients with the opportunity to select materials that are non-toxic, reusable
                  and renewable.
                </p>
                <p><strong>Systems are the Cream</strong></p>
                <p>
                  The use of everyday systems, like heating and cooling systems, in homes are the most energy hungry activities. By pairing
                  the effective management a household’s systems with good design principles Happy Haus is able to provide its clients with
                  the opportunity to not only have a positive effect on a buildings overall environmental performance but also significantly
                  reduce the overall running costs. Happy Haus can work with clients to design and implement systems that incorporate:
                  <ul>
                    <li>Solar power</li>
                    <li>Solar hot water</li>
                    <li>Grey water</li>
                    <li>Waste treatment</li>
                    <li>Use of 3+ star water efficient fittings and fixtures</li>
                    <li>LED Lighting.</li>
                  </ul>
                </p>
              </Copy>
            </Box>
            <Box width={4 / 12} px={2} pl={[0, 50]}>
              <PageResourceSide />
            </Box>
          </Flex>
        </Row>
      </>
    );
  }
}

export default Page;
