import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { theme } from '../util/style'

//<li><Link to="/resource-8-simple-steps-to-purchasing-a-new-home">8 simple steps to purchasing a new home</Link></li>

export default class PageResourceSide extends Component {
  render() {
    return (
      <List>
        <li>
          <Link to="/guide-knockdown-rebuild">Knockdown Rebuild</Link>
        </li>
        <li>
          <Link to="/builder-checklist">Builder Checklist</Link>
        </li>
        <li>
          <Link to="/guide-custom-haus">Custom Haus Guide</Link>
        </li>
        <li>
          <Link to="/resources">Land Guide</Link>
        </li>
      </List>
    )
  }
}
const List = styled.ul`
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
    a {
      color: ${theme.colors.grey1};
      border-top: 1px solid ${theme.colors.grey4};
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 20px;
    }
  }
`
